import React from "react"
import contactStyles from "./contact.module.scss"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
    }
  }

  handleChange = event => {
    if (event.target.value !== "") {
      event.target.className = `${contactStyles.input} ${contactStyles.complete}`
    } else {
      event.target.className = contactStyles.input
    }
  }

  handleButton = event => {
    let thisReady = true
    try {
      const inputs = document.getElementsByClassName(contactStyles.input) || [];
      Array.from(inputs).forEach((input) => {
        // eslint-disable-next-line
        if (input.value == "") {
          thisReady = false
        }
      });
      this.setState({
        ready: thisReady,
      })
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <form
        name="Contact Form"
        method="POST"
        data-netlify="true"
        action="/"
        className={contactStyles.form}
        onBlur={this.handleButton}
      >
        <input
          type="hidden"
          name="form-name"
          value="Contact Form"
          autoComplete="false"
        />
        <div>
          <label className={contactStyles.label}>
            <input
              type="email"
              name="email"
              autoComplete="off"
              className={contactStyles.input}
              onBlur={this.handleChange}
            />
            <span className={contactStyles.text}>Email</span>
          </label>
          <label className={contactStyles.label}>
            <input
              type="name"
              name="name"
              autoComplete="off"
              className={contactStyles.input}
              onBlur={this.handleChange}
            />
            <span className={contactStyles.text}>Name</span>
          </label>
        </div>
        <div className={contactStyles.message}>
          <label className={contactStyles.label}>
            <textarea
              name="message"
              autoComplete="off"
              className={contactStyles.input}
              onBlur={this.handleChange}
            />
            <span className={contactStyles.text}>Message</span>
          </label>
        </div>
        <button
          type="submit"
          disabled={!this.state.ready}
          className={contactStyles.button}
        >
          Send
        </button>
      </form>
    )
  }
}

export default ContactForm
