import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import ContactForm from "../components/contactForm"
import baseStyles from "./base.module.scss"
import contactStyles from "./contact.module.scss"

const ContactPage = () => {
  return (
    <>
      <Head title="Contact" />
      <Layout>
        <div className={baseStyles.wrapper}>
          <div className={baseStyles.container}>
            <ul>
              <li id="scroll" className={baseStyles.listItem}>
                <div className={contactStyles.splash}>
                  <div className={contactStyles.aligner}>
                    <h1>Contact</h1>
                    <h2>
                      Let's get in touch...
                      <br />
                      I'm always excited to <br />
                      talk tech!
                    </h2>
                  </div>
                </div>
              </li>
              <li className={baseStyles.listItem}>
                <ContactForm />
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ContactPage
